@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfbf6;
}

* {
  font-family: 'Inter', sans-serif;
}

.numeric-inter {
  font-variant-numeric: lining-nums tabular-nums slashed-zero;
  font-feature-settings: 'salt' on, 'ss04' on, 'cv05' on;
  letter-spacing: 0.84px;
}
